import React from "react"

import SEO from "../components/seo"
import DownloadsContent from "../components/downloadsContent"

// const oldestFirst = () => console.log("oldest first")

// const newestFirst = () => console.log("newest first")

const DownloadsPage = () => {
  return (
    <div className="downloadsPage pageContent base--shiftContent">
      <SEO
        title="Downloads"
        keywords={[
          `Sioux Lookout`,
          `hockey`,
          `downloads`,
          `files`,
          `documents`,
          `information`,
        ]}
      ></SEO>

      <h1 className="base__pageTitle">Downloads</h1>
      {/* <div>
        <button onClick={oldestFirst}>Oldest first</button>
        <button onClick={newestFirst}>Newest first</button>
      </div> */}

      <div className="downloadsPage__table">
        <DownloadsContent />
      </div>
    </div>
  )
}

export default DownloadsPage
